.pb-chatty,
.pb-onboard{
    position: fixed;
    z-index: 1050;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .5s;
    overflow-y: hidden;
    overflow-x: hidden;
    // padding-bottom: 2rem;
    transform: translateX(150%);
    background-color: rgba(var(--pb-c-chatty-bg), 1);

    .pb-logo-container{
      background-color: rgba(var(--pb-c-chatty-container-logo),1);
      svg path{
        fill: rgba(var(--pb-c-chatty-logo),1);
      }
    }

    &.pb-chatty--open{
        transform: translateX(0);

        .pb-bubble-animation{
            animation: slideIn 0.3s ease-in-out forwards;
            opacity: 0;
            transform: translateY(20px);
        }
        .pb-chatty-bubble:nth-child(2n + 1){
          @apply pb-max-w-full lg:pb-max-w-[70%];
          background-color: rgba(var(--pb-c-chatty-bubble-left), 1);
          animation-delay: 0.3s;
          width: fit-content;
          &:before {
              @apply pb-border-b-[24px] pb-border-l-[14px] lg:pb-border-b-[40px] lg:pb-border-l-[22px];
              content: "";
              /* border-left-width: 6px; */
              border-right-width: 0px;
              /* border-bottom-width: 15px; */
              border-color: transparent;
              border-bottom-color: rgba(var(--pb-c-chatty-bubble-left-border), 1);
              height: 0;
              left: 5px;
              bottom: -1px;
              right: -6px;
              position: absolute;
              overflow: hidden;
          }
        }

        .pb-chatty-bubble:nth-child(2n + 2){
          @apply pb-max-w-full lg:pb-max-w-[70%] pb-mt-4;
            background-color: rgba(var(--pb-c-chatty-bubble-right), 1);
            margin-bottom: 32px;
            width: fit-content;
            margin-left: auto;
            animation-delay: 1s;

            &:before {
                border-color: transparent;
                border-bottom-color: rgba(var(--pb-c-chatty-bubble-right-border), 1);
                border-left-width: 0;
                bottom: -1px;
                content: "";
                height: 0;
                left: 0px;
                overflow: hidden;
                position: absolute;
                right: 7px;
                border-top-width: 40px;
                border-right-width: 22px;
                border-bottom-width: 40px;
            }
        }
    }
}

.pb-chatty--selected{
  // @apply pb-bg-green-70 pb-text-white;
  background-color: rgba(var(--pb-palette-green-70), 1)!important;
  color: rgba(var(--pb-palette-white), 1)!important;
  
  html[data-energy-save="true"] {
    // @apply pb-bg-green-60 pb-text-white;
    background-color: rgba(var(--pb-palette-green-60), 1)!important;
    color: rgba(var(--pb-palette-white), 1)!important;

  }
}

@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(0px);
    }
    40% {
      opacity: 0.4;
      transform: translateY(0px);
    }
    80% {
      opacity: 0.8;
      transform: translateY(0px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(0px);
    }
    40% {
      opacity: 0.4;
      transform: translateY(0px);
    }
    80% {
      opacity: 0.8;
      transform: translateY(0px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  